
import { computed, defineComponent, reactive, toRefs } from 'vue'
import useVuelidate from '@vuelidate/core'
import Checkbox from '@/components/Checkbox.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import { ContactUs } from '@/models/ContactUs'
import {
  countiesList,
  enquiriesList,
  hoursList,
  minsList,
  repliesList,
  servicesList,
  titlesList,
  dynamicallyRules
} from '@/utils/ContactUsRulesAndLists'
import ArrowDropdown from '@/components/vectors/ArrowDropdown.vue'
import ModalLink from '@/components/CMSModalLink.vue'
import Select from '@/components/Select.vue'
import { useRouter } from 'vue-router'
import { contactUs } from '@/expressway-api/contactUs.api'
import useSnackbar from '@/composables/useSnackbar'

export default defineComponent({
  components: {
    Select,
    ArrowDropdown,
    DatePicker,
    Checkbox,
    ModalLink
  },
  setup () {
    const router = useRouter()

    const { setSnackbar } = useSnackbar()
    const state = reactive({
      title: '',
      fullName: '',
      email: '',
      dateOfTravel: '',
      dateOfTravelHour: '',
      dateOfTravelMin: '',
      typeOfService: 'Expressway Services',
      typeOfEnquiry: '',
      from: '',
      to: '',
      routeNo: '',
      details: '',
      likeReply: '',
      contactNumber: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      privacyPolicy: false,
      showModal: false,
      loadingContactUsCall: false
    })

    const services = servicesList
    const titles = titlesList
    const enquiries = enquiriesList
    const counties = countiesList
    const replies = repliesList
    const hours = hoursList
    const mins = minsList

    const rules = computed(() => dynamicallyRules(state.likeReply))

    const contactUsCall = async () => {
      const contact: ContactUs = {
        title: state.title,
        name: state.fullName,
        email: state.email,
        travelDateTime: `${state.dateOfTravel} ${state.dateOfTravelHour}:${state.dateOfTravelMin}`,
        serviceType: state.typeOfService,
        enquiryType: state.typeOfEnquiry,
        from: state.from,
        to: state.to,
        routeNo: state.routeNo,
        description: state.details,
        replyWanted: state.likeReply,
        phone: state.contactNumber,
        address: state.address,
        city: state.city,
        letterState: state.state,
        country: state.country,
        post: state.zipcode,
        consent: state.privacyPolicy ? '1' : '0'
      }

      try {
        state.loadingContactUsCall = true
        await contactUs(contact)
        await router.push({ name: 'ContactUsSuccess' })
      } catch (e) {
        setSnackbar(e.message)
        state.loadingContactUsCall = false
      }
    }

    return {
      v$: useVuelidate(rules, state),
      services,
      titles,
      enquiries,
      counties,
      replies,
      hours,
      mins,
      contactUsCall,
      ...toRefs(state)
    }
  }
})
